import React from 'react'
import { NavLink } from 'react-router-dom'
import { FaPhoneVolume } from "react-icons/fa";
import { MdMarkEmailUnread } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaCopyright } from "react-icons/fa";
export default function Footer() {
  return (
    <>
    <div className='bg-gray-900 px-4 py-10'>
      <div className='grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 text-white gap-2 '>
        <div className='md:space-y-2 space-y-1'>
          <h1 className='font-bold md:text-[20px] text-[16px] md:px-4 sm:px-2 px-1'>About Us</h1>
          <p className='md:text-[15px] text-[14px] font-medium py-2 text-start md:px-4 sm:px-2 px-1'>Adsonick is a dynamic creative agency engineered to drive significant online impact. We specialize in conceptualizing, designing, and crafting digital-first experiences that seamlessly bridge the gap between people and brands, bringing them together in the real world. </p>
        </div>
        <div className='lg:ml-[70px] space-y-2 sm:my-0 my-1'>
          <h1 className='font-bold md:text-[20px] text-[16px]'>Quick Links</h1>
          <ul className='text-start md:space-y-2 space-y-1'>
          <li><NavLink to={"/advertiser"} className='md:text-[15px] text-[14px] font-medium py-6 '>Advertisers</NavLink></li>
          <li><NavLink to={"/publishers"} className='md:text-[15px] text-[14px] font-medium py-4 '>Publishers</NavLink></li>
          <li> <NavLink to={"/contactus"} className='md:text-[15px] text-[14px] font-medium py-4 '>Contact Us</NavLink></li>
          <li><NavLink to={"/services"} className='md:text-[15px] text-[14px] font-medium py-4 '>Services</NavLink></li>
          </ul>
        </div>
        <div className='md:space-y-2 space-y-1 sm:my-0 my-2'>
          <h1 className='font-bold md:text-[20px] text-[16px]'>Useful Links</h1>
          <ul className='text-start md:space-y-2 space-y-1'>
          <li><NavLink to={"/terms"} className='md:text-[15px]  text-[14px] font-medium py-6 '>Terms & Conditions</NavLink></li>
          <li><NavLink to={"/privacy"} className='md:text-[15px] text-[14px] font-medium py-4 '>Privacy Policy</NavLink></li>
          <li> <NavLink to={"/refund"} className='md:text-[15px] text-[14px] font-medium py-4 '>Refund And Cancellation</NavLink></li>
          
          </ul>
        </div>
        <div className='md:space-y-2 space-y-1 sm:my-0 my-1'>
          <h1 className='font-bold md:text-[20px] text-[16px]'>Get in Touch</h1>
          <p className='flex  sm:py-2 text-start md:text-[15px] text-[14px]'><FaPhoneVolume className='md:text-[25px] sm:text-[20px] text-[18px] pr-2'/> +917206991113</p>
          <p className='flex  sm:py-2 text-start md:text-[15px] text-[14px]'><MdMarkEmailUnread   className='md:text-[25px] sm:text-[20px] text-[18px] pr-2'/>Kishan.singla@adsonick.com</p>
          <p className='flex sm:py-2 text-start md:text-[15px] text-[14px]'><FaLocationDot  className='md:text-[30px] sm:text-[20px] text-[18px] pr-2'/>3RD FLOOR, LANDMARK CYBER, PARK, SECTOR 67, Gurgaon Kty., Gurgaon Kty., Gurgaon- 122001, Haryana</p>
        </div>
      </div>
      <hr  className="md:mt-8 mt-4 py-2 sm:px-4 px-2" />
      <div className='flex justify-between'>
      <div className='flex'>
      <FaCopyright className='text-gray-300 md:text-[20px] text-[16px] pt-1' />
      <p className='text-gray-300 px-1 lg:text-[18px] sm:text-[16px] text-[14px]'>All Rights are reserved by Adsonick</p>
      </div>
      <NavLink to={"https://www.adomobi.com/"}><div>
        <p className='text-gray-300 lg:text-[18px] sm:text-[16px] text-[14px] '>Developed By AdoMobi</p>
      </div></NavLink>
      </div>
    </div>
    </>
  )
}