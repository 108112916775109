import React from 'react'
import team from "../assets/images/aiimage5.png"
import email from "../assets/images/email (1).png"
import display from "../assets/images/yahoo.png"
import mobilead from "../assets/images/mobilead.png"
import amazon from "../assets/images/amazon1.png"
import myntra from "../assets/images/myntra.png"
import mpl from "../assets/images/mpl.jpeg"
import kotak from "../assets/images/kotak.png"
import honda from "../assets/images/honda.jpeg"
import aiimage from "../assets/images/aiimage4.jpg"
import prakhar from "../assets/images/prakhar.png"
import kishan from "../assets/images/kishan.png"
import nitin from "../assets/images/nitin.jpg"
import { useTypewriter,Cursor } from 'react-simple-typewriter'
import { NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


export default function Home() {
  const [typeEffect]=useTypewriter({
    words:["Adsonick a Digital Marketing Agency "],
    loop:{},
    typeSpeed:120,
    deleteSpeed:160,
  })

  const card=[
    {
      id:1,
      imgsrc: mobilead ,
      title:"Web & Mobile Advertising",
      description:"Our web and mobile advertising campaigns are designed to reach millions of smartphone users worldwide, resulting in over 10 million mobile app installs."
    },
    {
      id:2,
      imgsrc: email ,
      title:"SMS & Email Marketing",
      description:"Our email marketing and SMS strategies provide personalized solutions to connect with the right audience, enabling your brand to engage effectively on these platforms."
    },
    {
      id:3,
      imgsrc: display,
      title:"Display Advertising",
      description:"Display advertising visually communicates commercial messages through text, logos, animations, videos, and photographs."
    },
  ]

  const TeamMember=[
    // {
    //   id:1,
    //   pic:prakhar,
    //   name:"Prakhar Shukla",
    //   designation:"Founder & Business Head",
    //   description:"Meet Prakhar Shukla, the visionary Founder & Business Head at We24seven. With a strategic mind and a commitment to excellence, he leads our team, shaping the company’s growth and fostering innovation.",
    // },
    {
      id:2,
      pic:kishan,
      name:"Kishan Singla",
      designation:"Founder & Operation Head",
      description:"Meet Kishan Singla, the dynamic force and Founder & Operation Head at Adsonick. With an operational prowess and a strategic mindset, he plays a pivotal role in ensuring seamless day-to-day functioning.",
    },
    // {
    //   id:3,
    //   pic:nitin,
    //   name:"Nitin Rekhan",
    //   designation:"Co-Founder & Finance Head",
    //   description:"Meet Nitin Rekhan, the Co-Founder & Finance Head at We24Seven. With a keen eye for financial strategy, he is instrumental in steering the financial success of our company.",
    // }
  ]

  return (
    <>
    <main>
      <div className="container lg:py-16 py-2 lg:px-4 px-2 dark:bg-black">
        <div className='grid md:grid-cols-2 grid-cols-1 py-2'>
        <motion.div  
             initial={{ x: -1000 }}
             animate={{ x: 0 }}
             transition={{
             duration: 1,
             delay: 0.2
            }} className='mt-[60px]' >
            <h2 className='lg:text-[40px] md:text-[30px] text-[25px] font-bold md:px-4 px-2 dark:text-white'>Welcome To </h2> 
              <span className='font-bold text-purple-900 dark:text-purple-500 md:px-4 px-2 lg:text-[25px] md:text-[20px] text-[18px]'>{ typeEffect}</span>
            <p className='lg:text-[18px] md:text-[16px] text-[15px] lg:py-4 md:py-3 py-2 lg:px-6 md:px-4 px-2 text-gray-700 dark:text-white'>In a landscape filled with digital clutter, we elevate your brand by seamlessly integrating it into the lives of today's connected consumers, ensuring lasting impact.</p>
            <div className='lg:mx-[25px] md:mx-[18px] mx-[12px] my-2'><button className='lg:text-[18px] md:text-[16px] text-[15px] md:px-4 px-2 md:py-2 py-1 bg-purple-900 font-semibold text-white rounded-md hover:shadow-md hover:shadow-gray-500'>Get Started</button></div>
          </motion.div>
          <motion.div 
             initial={{ x: 1000 }}
             animate={{ x: 0 }}
             transition={{
             duration: 1,
             delay: 0.2
            }} 
             className=''>
            <img src={aiimage} className='mx-auto' height={300} />
          </motion.div>
        </div>
        <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:py-3 py-2 md:px-4 px-2 lg:gap-6 md:gap-4 gap-3 md:my-4'>
          {
            card.map((data,index)=>(
         <motion.div  whileHover={{scale: 1.1}} transition={{layout:{duration:1,type:"spring"}}} layout  className='shadow-gray-500 shadow-md border-gray-500 md:px-3 px-2 md:py-3 py-2 rounded-md bg-purple-200 dark:bg-white' key={index}>
          <div className='pt-2'><img src={data.imgsrc} className='animate-bounce'/></div>
          <div><h1 className='lg:text-[20px] md:text-[16px] text-[14px] font-bold lg:pt-5 md:pt-4 pt-2 dark:text-purple-900'>{data.title}</h1></div>
          <div><p className='lg:text-[16px] md:text-[14px] text-[13px] text-gray-700 py-2'>{data.description}</p></div>
         </motion.div>
          ))}
        </div>
        <motion.div initial={{opacity: 0,scale: 0}} whileInView={{opacity: 1,scale: 1}} transition={{duration:1}} className='grid lg:grid-cols-2 grid-cols-1 md:px-4 px-2'>
          <div><img src={team} className='mx-auto' width={450} height={300}/></div>
          <div className='lg:py-12 md:pt-1 md:pb-6'>
           <h1 className='lg:text-[30px] md:text-[20px] text-[18px] font-bold dark:text-purple-500 text-center lg:text-start'>Discovering Our Digital Identity: A Look into Who We Are</h1>
           <h2 className='lg:text-[18px] md:text-[16px] text-[15px] lg:py-6 md:py-2 py-1 text-gray-700 dark:text-white lg:text-start text-center'>Adsonick creates seamless digital brand experiences across mobile, web, and apps using cutting-edge technology.</h2>
           <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 dark:text-white py-1'>We are a network of meticulously chosen, highly skilled, independently operated marketing and advertising agencies with a digital foundation. As the most comprehensive and genuine result-driven ad network, we provide a tailored package of features for each client.</p>
          </div>
        </motion.div>
        <div className='py-2 lg:px-4 px-2'>
         <h1 className='text-center lg:text-[30px] md:text-[20px] text-[18px] font-bold md:py-6 py-4'>How can we help you !</h1>
         <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:py-4 py-2 lg:mx-10 lg:my-6 my-5 lg:gap-6 md:gap-4 gap-3'>
          <motion.div  whileHover={{scale: 1.1}} transition={{layout:{duration:1,type:"spring"}}} layout className='bg-purple-200 md:px-4 px-2 md:py-4 py-3 md:text-center rounded-md dark:bg-white'>
            <div className='text-center'><i className="fa-solid fa-magnifying-glass-dollar lg:text-[30px] md:text-[20px] text-[18px] dark:text-purple-900"></i></div>
            <h1 className='lg:text-[20px] md:text-[18px] text-[16px] font-bold lg:py-4 py-2 dark:text-purple-900'>Web Marketing</h1>
            <p className='md:text-[16px] text-[14px] text-gray-700'>Our web advertising campaigns reach the maximum number of smartphone and desktop users worldwide. To date, the products we've promoted have achieved an impressive 10 million users.</p>
          </motion.div>
          <motion.div  whileHover={{scale: 1.1}} transition={{layout:{duration:1,type:"spring"}}} layout className='bg-purple-900 md:px-4 px-2 md:py-4 py-3 md:text-center rounded-md'>
            <div className='text-center'><i className="fa-solid fa-rectangle-ad lg:text-[30px] md:text-[20px] text-white"></i></div>
            <h1 className='lg:text-[20px] md:text-[18px] text-[16px] font-bold lg:py-4 py-2 text-white'>SMM Marketing</h1>
            <p className='md:text-[16px] text-[14px] text-white'>SMM (Social Media Marketing) for your online business. Our unique targeting strategy, which includes Geo, OS and Version, Category, Carrier, Desktop/Mobile, and Browser, enables precise financial forecasting.</p>
          </motion.div>
          <motion.div  whileHover={{scale: 1.1}} transition={{layout:{duration:1,type:"spring"}}} layout className='bg-purple-200 md:px-4 px-2 md:py-4 py-3 md:text-center rounded-md dark:bg-white'>
            <div className='text-center'><i class="fa-solid fa-mobile-screen-button lg:text-[30px] md:text-[20px] text-[18px] dark:text-purple-900"></i></div>
            <h1 className='lg:text-[20px] md:text-[18px] text-[16px] font-bold lg:py-4 py-2 dark:text-purple-900'>Mobile Marketing</h1>
            <p className='md:text-[16px] text-[14px] text-gray-700'>Experience excellence in mobile marketing with us. As pioneers in delivering innovative technical solutions on the digital platform, we operate from multiple international locations, run campaigns in over 120 countries, and collaborate with partners in 75+ regions.</p>
          </motion.div>
         </div>
        </div>
        <div className='bg-purple-200 lg:py-14 md:py-10 py-8 md:px-4 px-2 md:my-10 my-8 dark:bg-white dark:text-purple-900'>
          <h1 className='lg:text-[25px] md:text-[20px] text-[18px] text-center font-bold'>Professional Digital Marketing Services to Elevate Your Online Presence</h1>
          <p className='lg:text-[18px] md:text-[16px] text-[14px] font-semibold text-center '>Contact us today to schedule a free consultation and discover how we can help you achieve your digital marketing goals.</p>
          <NavLink to={"/contactus"}><div className='flex'><button className='md:text-[16px] md:px-4 px-2 py-2 mx-auto bg-purple-900 rounded-3xl text-white lg:w-[12%] mt-5'>Contact Us</button></div></NavLink>
        </div>
        <div className='md:py-10 md:px-4 px-2 lg:my-10 my-8'>
          <h1 className='lg:text-[30px] md:text-[20px] text-[18px] text-center py-2 dark:text-purple-500'>Building Meaningful<b> Industry Relationships</b></h1>
          <div className='flex justify-center lg:my-4 my-2'>
            <div className='border-r-2 border-purple-900 lg:text-[20px] md:text-[18px] text-[16px] font-bold px-2 dark:text-purple-500'>Clients</div>
            <div className='lg:text-[20px] md:text-[18px] text-[16px] font-semibold px-2 pb-2 dark:text-purple-500'>Partners</div>
          </div>
          <Swiper
           spaceBetween={30}
           centeredSlides={true}
           autoplay={{
           delay: 2500,
           disableOnInteraction: false,
          }}
          pagination={{
          clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
         >
        <SwiperSlide>
        <div className='grid grid-cols-5 gap-2 dark:bg-white'>
        <div className='border-black border-[1px]'><img src={amazon} width={100} height={100} className='mx-auto'/></div>
        <div className='border-black border-[1px]'><img src={myntra} width={100} height={100} className='mx-auto'/></div>
        <div className='border-black border-[1px]'><img src={kotak} width={100} height={100} className='mx-auto my-8'/></div>
        <div className='border-black border-[1px]'><img src={honda} width={100} height={100} className='mx-auto'/></div>
        <div className='border-black border-[1px]'><img src={mpl} width={100} height={100} className='mx-auto'/></div>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='grid grid-cols-5 gap-2 dark:bg-white'>
        <div className='border-black border-[1px]'><img src={amazon} width={100} height={100} className='mx-auto'/></div>
        <div className='border-black border-[1px]'><img src={myntra} width={100} height={100} className='mx-auto'/></div>
        <div className='border-black border-[1px]'><img src={kotak} width={100} height={100} className='mx-auto my-8'/></div>
        <div className='border-black border-[1px]'><img src={honda} width={100} height={100} className='mx-auto'/></div>
        <div className='border-black border-[1px]'><img src={mpl} width={100} height={100} className='mx-auto'/></div>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='grid grid-cols-5 gap-2 dark:bg-white'>
        <div className='border-black border-[1px]'><img src={amazon} width={100} height={100} className='lg:mx-auto'/></div>
        <div className='border-black border-[1px]'><img src={myntra} width={100} height={100} className='lg:mx-auto'/></div>
        <div className='border-black border-[1px]'><img src={kotak} width={100} height={100} className='lg:mx-auto lg:my-8'/></div>
        <div className='border-black border-[1px]'><img src={honda} width={100} height={100} className='lg:mx-auto'/></div>
        <div className='border-black border-[1px]'><img src={mpl} width={100} height={100} className='lg:mx-auto'/></div>
        </div>
        </SwiperSlide>
      </Swiper>
      
        </div>
        <motion.div  whileHover={{scale: 1.1}} transition={{layout:{duration:1,type:"spring"}}} layout className='md:py-4 md:px-4 lg:my-10 md:my-8'>
          <h1 className='lg:text-[25px] text-[20px] text-center font-bold dark:text-purple-500 py-2'>Meet Our Team</h1>
          <p className='md:text-[16px] text-gray-700 text-center lg:px-10 md:px-4  dark:text-white'>Meet our team of seasoned professionals! With extensive experience and a deep passion for the industry, our experts are committed to helping you reach your objectives. Whether you need digital marketing, design, or other specialized services, we're here to support your success.</p>
          <div className='mx-auto md:w-[40%] md:gap-4 gap-2 my-8 md:py-6 py-4'>
            {
              TeamMember.map((d,index)=>(
            <div className=' rounded-2xl shadow-xl shadow-gray-600 dark:bg-white' key={index}>
              <div className='bg-purple-900 rounded-t-2xl'>
                <div className=''><img src={d.pic} className='rounded-full p-4 mx-auto' height={150} width={150}/></div>
              </div>
              <div className=''>
                <h1 className='text-center font-bold t-2 lg:text-[20px] md:text-[16px] text-[15px] sm:px-2 px-1 py-2'>{d.name}</h1>
                <p className='text-center font-semibold lg:text-[18px] md:text-[15px] text-[14px]  sm:px-2 px-1 lg:py-2'>{d.designation}</p>
                <p className='md:px-4 py-2 sm:px-2 px-1 font-medium text-gray-700 text-start lg:text-[16px] md:text-[14px] text-[13px]'>{d.description}</p>
              </div>

            </div>
            ))}
          </div>
        </motion.div>
      </div>
    </main>  
    </>
  ) 
}
