import React from 'react'
import advertiser from "../assets/images/advertiser2.jpg"
import { MdSpatialTracking } from "react-icons/md";
import { MdDangerous } from "react-icons/md";
import { FcSupport } from "react-icons/fc";
import { MdHighQuality } from "react-icons/md";
import { FaNetworkWired } from "react-icons/fa6";
import { MdOutlinePayment } from "react-icons/md";
import { FaDotCircle } from "react-icons/fa";
import { NavLink } from 'react-router-dom';


export default function Advertiser() {
  const advantageData=[
    {
      id:1,
      icon:<MdSpatialTracking />,
      advantageName:"Intelligent Tracking and Real-time Reporting",
      description:"At Adsonick, you can access detailed and comprehensive news. Our intuitive, screen-optimized dashboard lets you monitor your performance and sales anytime. We optimize and measure every performance indicator to maximize your digital investment returns, enabling you to make informed, real-time decisions based on actionable data."
    },
    {
      id:2,
      icon:<MdDangerous />,
      advantageName:"Anti-Fraud System",
      description:"Adsonick maintains a zero-tolerance policy for dishonest affiliates. We closely monitor publishers to ensure they do not use unethical methods to boost traffic and sales, scrutinizing the quality of their traffic. Our advanced fraud prevention tools allow our real-time tracking systems to detect suspicious activity and halt commission transfers immediately upon identifying fraud."
    },
    {
      id:3,
      icon:<FcSupport />,
      advantageName:"24/7 Support",
      description:"Our dedicated technical support team is always available to assist with any issues or questions. Since the internet never sleeps, you can reach out to us anytime for help. Our experienced staff will ensure you have the perfect account manager to enhance your company's unique identity."   
 },
    {
      id:4,
      icon:<MdHighQuality />,
      advantageName:"Quality & Compliance",
      description:"In maintaining your brand online, we focus on the quality of leads, ensuring maximum assurance with minimal risk to boost both volume and quality. We guide publishers on how to use your material to promote your brand and product, expanding your online reach while adhering to each advertiser's guidelines. Our expert team ensures your content and creative assets are approved, accurate, well-targeted, up-to-date, and compliant."
    },
    {
      id:5,
      icon:<FaNetworkWired />,
      advantageName:"Extensive Network of Publishers",
      description:"With our network of over 3,800 reputable publishers, we can help you reach millions of potential customers and increase your sales. Our dedicated team works closely with a select group of partners, ensuring we find publishers that meet your program's performance standards, creating additional opportunities to drive sales growth."
    },
    {
      id:6,
      icon:<MdOutlinePayment />,
      advantageName:"Payments Management",
      description:"We manage all aspects of commission handling and partner communication for you. Simply make one payment to us, and we'll handle the rest, including distributing payments to performance-based partners and individual affiliates."
    },
  ]

  const Campaign=[
    {
      id:1,
      icon:<FaDotCircle/>,
      name:"Cost Per Mail (CPM)",
    },
    {
      id:2,
      icon:<FaDotCircle/>,
      name:"Cost Per Registration (CPR)",
    },
    {
      id:3,
      icon:<FaDotCircle/>,
      name:"Cost Per Click (CPC)",
    },
    {
      id:4,
      icon:<FaDotCircle/>,
      name:"Cost Per Acquisition (CPA)",
    },
    {
      id:5,
      icon:<FaDotCircle/>,
      name:"Cost Per Visit (CPV)",
    },
    {
      id:6,
      icon:<FaDotCircle/>,
      name:"Cost Per Open (CPO)",
    },
    {
      id:7,
      icon:<FaDotCircle/>,
      name:"Cost Per Installation (CPI)",
    },
    {
      id:8,
      icon:<FaDotCircle/>,
      name:"Cost Per Sale (CPS)",
    },
    {
      id:9,
      icon:<FaDotCircle/>,
      name:"Cost Per Lead (CPL)",
    },
  ]
  return (
    <>
    <div className='bg-white'>
    <div  style = {{
               backgroundImage:
               `url('${advertiser}')`,
               backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
               width: '100%',
               height: '40vh',
               opacity: 0.7,
            }} className='lg:text-[50px] sm:text-[40px] text-[30px]  text-center font-bold  py-52'>
              Advertiser
      </div>
            {/* <div className='absolute lg:top-[150px] md:top-[300px] sm:top-[250px] top-[300px] lg:left-[500px] md:left-[300px] sm:left-[200px] left-[100px]'>
              <h1 className='lg:text-[50px] sm:text-[40px] text-[30px]  text-center font-bold'>Advertisers</h1>
            </div> */}
            <div className='lg:px-10 sm:px-5 px-2 lg:py-12 py-8 dark:bg-black dark:text-white'>              <h1 className='lg:text-[30px] md:text-[20px] text-[18px] text-center font-semibold underline-border py-6 dark:text-purple-500'>New to affiliate marketing? No worries!</h1>
              <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700  dark:text-white px-2 lg:py-2 py-1'>Adsonick is committed to introducing new advertisers to the benefits of a thriving affiliate marketing program. From small businesses to large corporations, we manage millions of transactions for a diverse range of advertisers. Our cutting-edge technology ensures that your offer is showcased directly on the customer's website, dynamically selecting the best bid in real-time.</p>
              <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700  dark:text-white px-2 py-2'>With We24Seven, you pay solely for results, providing our advertisers with a secure environment to expand their online business. Target your audience and harness the power of our integrated technology, seamlessly blending advancements, human understanding, and expert experience.</p>
              <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 dark:text-white px-2 py-2'>Partner with our network to collaborate with top affiliates and access high-quality, high-volume traffic that drives sales and boosts profitability through performance-based marketing. Let us help you connect with the right customers in efficient and cost-effective ways.</p>
            </div>
            <div className='lg:px-10 sm:px-5 px-2 py-4 dark:bg-black dark:text-white'>
              <h1 className='lg:text-[30px] md:text-[20px] text-[18px] text-center font-semibold underline-border py-4 dark:text-purple-500'>How It Works</h1>
              <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 dark:text-white px-2 py-2'>Affiliate marketing is one of the most effective methods to generate revenue for your website. By registering as an advertiser on Adsonick, you will connect with publishers eager to promote your products on their platforms. Potential customers leave impressions after viewing these advertisements, and with accurate audience targeting, many convert into buyers. Key metrics such as clicks, impressions, and engagement help track how advertisers generate income.</p>
              <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 dark:text-white px-2 py-2'>Leveraging our cutting-edge technology, extensive experience, and expertise, we drive sales, expand your reach, and enhance brand preference, engagement, and loyalty. Our services are flexible and innovative, as we are committed to exploring new ideas and applying creative strategies to ensure a reliable source of qualified traffic for your business.</p>
              <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 dark:text-white px-2 py-2'>Our extensive network of over 3,800 active partners across various sectors allows us to connect you with the best publishers for your affiliate program. Every publisher in our network undergoes a rigorous screening process before joining, ensuring high-quality partnerships.</p>
            </div>
            <div className='lg:px-10 sm:px-5 px-2 lg:py-4 py-2 dark:bg-black dark:text-white'>
            <h1 className='lg:text-[30px] md:text-[20px] text-[18px] text-center font-semibold underline-border py-6 dark:text-purple-500'>Discover Your Advantages</h1>
            <div className='grid md:grid-cols-3 grid-cols-1 lg:gap-6 gap-4'>
              {
                advantageData.map((item,index)=>(
              <div className='rounded-md shadow-md shadow-gray-600 px-3 my-3 bg-purple-200 dark:bg-white dark:text-black' key={index}>
                <div className='lg:text-[50px] md:text-[40px] sm:text-[30px] text-[25px] flex justify-center py-2 text-black dark:text-purple-900'>{item.icon}</div>
                <h1 className='lg:text-[18px] sm:text-[16px] text-[15px] font-bold lg:py-3 py-2 text-center dark:text-purple-900 '>{item.advantageName}</h1>
                <p className='lg:text-[15px] text-[14px] py-2 text-gray-700  '>{item.description}</p>
              </div>
              ))
              }
            </div>
            </div>
            <div className='lg:px-10 sm:px-5 px-2 lg:py-4 py-2 dark:bg-black dark:text-white'>
            <h1 className='lg:text-[30px] md:text-[20px] text-[18px] text-center font-semibold underline-border py-4 dark:text-purple-500'>Join As Advertiser</h1>
            <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 px-2 py-2 dark:text-white'>Our expert Media Buying & Planning team helps brands achieve their objectives through strategic inventory management. We boost digital presence, facilitating advertising on both web and mobile platforms. By guiding brands in choosing the most effective medium and module, we maximize ROI and ensure you connect with high-quality customers.</p>
            <div className='md:w-[50%] mx-auto  my-4 px-2 py-2 rounded-md shadow-md shadow-gray-500 bg-purple-200 dark:bg-white dark:text-black'>
              <h1 className='py-4 px-2 text-center font-bold lg:text-[18px] text-[16px] dark:text-purple-900'>Our Modes of Campaign</h1>
              <div className='grid grid-cols-2'>
                {
                  Campaign.map((items,index)=>(
                <div className='flex lg:px-2 px-1 py-2' key={index} ><div className='text-purple-900'>{items.icon}</div><p className='lg:text-[16px] md:text-[15px] text-[14px] hover:underline hover:underline-offset-8 px-3'>{items.name}</p></div>
                ))}
              </div>
              <div className='flex md:my-4 my-2 justify-center'><NavLink to={"/contactus"}><button className='bg-purple-900 md:px-4 px-2 py-2 lg:text-[16px] md:text-[15px] text-[14px] rounded-md text-white font-semibold'>Contact Us</button></NavLink></div>
            </div>
            </div>
    </div>
    </>
  )
}
