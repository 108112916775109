import React from 'react'

export default function PrivacyPolicy() {
  return (
    <>
      <div className='md:py-30 md:mt-10 py-10 px-4 dark:text-white dark:bg-black'>
      <h1 className='text-center font-bold lg:text-[30px] sm:text-[20px] text-[18px] dark:text-purple-500'>PRIVACY POLICY</h1>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-6 pb-6 lg:px-8 sm:px-4 px-2'>We24Seven We operates the website www.we24seven.com. This page informs you of our policies regarding the collection, use, and disclosure of personal information we receive from users of the Site.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px]  pb-6 lg:px-8 sm:px-4 px-2'>We use your personal information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.</p>
      <h1 className='text-start text-black font-semibold dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 lg:px-8 sm:px-4 px-2'>Information Collection and Use</h1>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 pb-2 lg:px-8 sm:px-4 px-2'>While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your name, email address, phone number, and postal address. We collect this information for the purpose of providing the services offered by our Site and to communicate with you regarding your use of the Site.</p>
      <h1 className='text-start text-black font-semibold dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 lg:px-8 sm:px-4 px-2'>Log Data</h1>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 pb-2 lg:px-8 sm:px-4 px-2'>Like many site operators, we collect information that your browser sends whenever you visit our Site (“Log Data”). This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 pb-6 lg:px-8 sm:px-4 px-2'>In addition, we may use third-party services such as Google Analytics that collect, monitor, and analyze this type of information in order to increase our Site’s functionality. These third-party service providers have their own privacy policies addressing how they use such information.</p>
      <h1 className='text-start text-black font-semibold dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 lg:px-8 sm:px-4 px-2'>Cookies</h1>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 pb-2 lg:px-8 sm:px-4 px-2'>Cookies are files with small amounts of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer’s hard drive. Like many sites, we use “cookies” to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 pb-2 lg:px-8 sm:px-4 px-2'>Security The security of your personal information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. Changes to This Privacy Policy We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on the Site. You are advised to review this privacy policy periodically for any changes.</p>
      <h1 className='text-start text-black font-semibold dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 lg:px-8 sm:px-4 px-2'>Contact Us</h1>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 pb-6 lg:px-8 sm:px-4 px-2'>If you have any questions about this privacy policy, please contact us at info@adsonick.com.</p>
      </div>
    </>
  )
}
