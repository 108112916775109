import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import DarkMode from "../Components/DarkMode";
import logo from "../assets/images/Adsonick logo design.png"


export default function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const MenuLinks = [
    { id: 1, name: "Home", link: "/" },
    { id: 2, name: "Services", link: "/services" },
    { id: 3, name: "Advertiser", link: "/advertiser" },
    { id: 4, name: "Publishers", link: "/publishers" },
    // { id: 5, name: "Contact Us", link: "/contactus" },
  ];

  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  return (
    <>
      <nav className='fixed top-0 left-0 w-full z-50 bg-purple-100 dark:bg-black dark:text-white duration-300'>
        <div className='container md:py-4 py-2 lg:px-12 md:px-6 px-2'>
          <div className='flex md:gap-6 gap-4 items-center font-medium justify-between'>
            {/* logo section */}
            <div className='font-bold lg:text-[16px] text-[14px]'>
              <NavLink to={"/"}><img src={logo} className='lg:w-[200px] w-[100px]'/></NavLink>
            </div>
            {/* hamburger menu and dark mode toggle for mobile */}
            <div className='lg:hidden flex items-center'>
              <DarkMode />
              <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className='ml-4'>
                {isMobileMenuOpen ? <i className="fa-solid fa-xmark"></i> : <i className="fa-solid fa-bars"></i>}
              </button>
            </div>
            {/* navlinks section for larger screens */}
            <div className='hidden lg:flex'>
              <ul className='flex items-center md:gap-8 gap-4'>
                {
                  MenuLinks.map(({ id, name, link }) => (
                    <li key={id} className='cursor-pointer py-2'>
                      <NavLink to={link} className='md:text-[16px] text-[14px] font-semibold hover:text-purple-900 hover:border-b-2 hover:border-purple-500 transition-all duration-200'>
                        {name}
                      </NavLink>
                    </li>
                  ))
                }
                {/* contact us button */}
                <NavLink to={"/contactus"}>
                  <button className='font-semibold border-purple-500 md:text-[16px] text-[14px] bg-purple-900 px-2 py-1 rounded-md text-white'>
                    Contact Us
                  </button>
                </NavLink>
                <DarkMode />
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* Sidebar for mobile view covering full width */}
      <div className={`fixed top-0 left-0 w-full h-full bg-purple-100 dark:bg-black dark:text-white transform ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out z-50`}>
        <button onClick={closeMobileMenu} className='absolute top-4 right-4'>
          <i className="fa-solid fa-xmark"></i>
        </button>
        <ul className='flex flex-col items-start p-4 mt-10'>
          {
            MenuLinks.map(({ id, name, link }) => (
              <li key={id} className='cursor-pointer py-2'>
                <NavLink to={link} className='md:text-[16px] text-[14px] font-semibold hover:text-purple-900 transition-all duration-200' onClick={closeMobileMenu}>
                  {name}
                </NavLink>
              </li>
            ))
          }
          {/* contact us button */}
          <NavLink to={"/contactus"}>
            <button className='font-semibold border-purple-500 md:text-[16px] text-[14px] bg-purple-900 px-2 py-1 rounded-md text-white mt-4' onClick={closeMobileMenu}>
              Contact Us
            </button>
          </NavLink>
        </ul>
      </div>
      
      {/* Overlay to close the menu when clicking outside */}
      {isMobileMenuOpen && <div className="fixed inset-0 bg-black opacity-50 z-40" onClick={closeMobileMenu}></div>}
    </>
  );
}
