import React from 'react'

export default function RefundCancillation() {
  return (
    <>
      <div className='md:py-20 md:mt-10 py-10 px-4 dark:text-white dark:bg-black'>
      <h1 className='text-center font-bold lg:text-[30px] sm:text-[20px] text-[18px] dark:text-purple-500'>REFUND AND CANCELLATION POLICIES</h1>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-6 pb-6 lg:px-8 sm:px-4 px-2'>Thank you for choosing We24Seven for your digital marketing needs. We strive to provide you with the best service and support. Please review our refund and cancellation policy below:</p>
      <h1 className='text-start text-black font-semibold dark:text-white text-[16px] pt-4  px-8'>Refund Policy:</h1>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 pb-2 lg:px-8 sm:px-4 px-2'>1. Service Fees: We24Seven provides customized digital marketing services tailored to each client’s specific requirements. As such, service fees are non-refundable once the services have been rendered or initiated.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 pb-2 lg:px-8 sm:px-4 px-2'>Satisfaction Guarantee: We are committed to your satisfaction. If you are not completely satisfied with our services within the first 30 days of engagement, please contact our customer support team. We will work with you to address any concerns and find a suitable resolution.</p>
      <h1 className='text-start text-black font-semibold dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4  lg:px-8 sm:px-4 px-2'>Cancellation Policy:</h1>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 pb-2 lg:px-8 sm:px-4 px-2'>1. Service Cancellation: Should you wish to cancel your services with We24Seven, please notify us in writing at least 30 days prior to the desired cancellation date. Any fees already paid for the upcoming period will not be refunded but will be honored until the end of the prepaid period.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 pb-2 lg:px-8 sm:px-4 px-2'>2. Termination: We24Seven reserves the right to terminate services in cases of non-compliance with our terms and conditions, unethical practices, or misuse of our services. In such instances, no refunds will be provided for the remaining unused services.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 pb-2 lg:px-8 sm:px-4 px-2'>Please note that any third-party expenses or costs incurred by We24Seven on your behalf will not be refunded.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 pb-2 lg:px-8 sm:px-4 px-2'>We encourage you to reach out to our customer support team with any questions or concerns regarding our refund and cancellation policies. We are here to assist you and ensure a positive experience.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-4 pb-2 lg:px-8 sm:px-4 px-2'>Thank you for choosing We24Seven.</p>
      </div>
    </>
  )
}
