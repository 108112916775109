import React from 'react'

export default function TermsCondition() {
  return (
    <>
      <div className='lg:py-20 md:mt-10 py-10 px-4 dark:text-white dark:bg-black '>
        <h1 className='text-center font-bold lg:text-[30px] sm:text-[20px] text-[18px] dark:text-purple-500'>TERMS & CONDITIONS</h1>
        <p className='text-center text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] pt-2 pb-6'>Welcome to Adsonick . By using this Site, you agree to comply with and be bound by the following terms and conditions of use:</p>
       <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] py-2 lg:px-8 sm:px-4 px-2'>1. The content of the pages of this Site is for your general information and use only. It is subject to change without notice.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] lg:px-8 sm:px-4 px-2 py-2'>2. Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this Site for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] lg:px-8 sm:px-4 px-2 py-2'>3. Your use of any information or materials on this Site is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this Site meet your specific requirements.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] lg:px-8 sm:px-4 px-2 py-2'>4. This Site contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] lg:px-8 sm:px-4 px-2 py-2'>5. All trademarks reproduced on this Site, which are not the property of, or licensed to the operator, are acknowledged on the Site.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] lg:px-8 sm:px-4 px-2 py-2'>6. Unauthorized use of this Site may give rise to a claim for damages and/or be a criminal offense.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] lg:px-8 sm:px-4 px-2 py-2'>7. From time to time, this Site may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] lg:px-8 sm:px-4 py-2'>8. Your use of this Site and any dispute arising out of such use of the Site is subject to the laws of India.</p>
      <p className='text-start text-gray-500 dark:text-white lg:text-[16px] md:text-[15px] text-[14px] py-8 lg:px-4 px-2'>By accessing or using the Site, you agree to these terms and conditions. If you do not agree with any part of these terms and conditions, do not use the Site.</p>
      </div>
    </>
  )
}
