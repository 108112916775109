import React, { useState } from 'react';
import publisher from "../assets/images/publisher1.jpg";
import { LuPanelTopOpen } from "react-icons/lu";
import { IoIosPeople } from "react-icons/io";
import { IoPush } from "react-icons/io5";
import { FaBuysellads, FaVideo, FaArrowRight } from "react-icons/fa";
import { GiVerticalBanner } from "react-icons/gi";
import popunder from "../assets/images/popunder1.png";
import socialbar from "../assets/images/socialbar.jpeg";
import push from "../assets/images/pushnotification.jpeg";
import native from "../assets/images/native.png";
import videoads from "../assets/images/videoad.jpeg";
import bannerad from "../assets/images/bannerad1.jpeg";
import { NavLink } from 'react-router-dom';


export default function Publishers() {
  const [selectedAdIndex, setSelectedAdIndex] = useState(0);

  const ads = [
    { id: 1, icon: <LuPanelTopOpen />, name: "Popunder Ads" },
    { id: 2, icon: <IoIosPeople />, name: "SocialBars Ads" },
    { id: 3, icon: <IoPush />, name: "Push-Notification" },
    { id: 4, icon: <FaBuysellads />, name: "Native Ads" },
    { id: 5, icon: <FaVideo />, name: "Video Ads" },
    { id: 6, icon: <GiVerticalBanner />, name: "Banner Ads" },
  ];

  const adsDetails = [
    {
      id: 1,
      title: "Popunder Ads",
      imgsrc: popunder,
      imgWidth: 400,
      imgHeight: 400,
      icon: <FaArrowRight />,
      description: "Pop-under ads are a type of online advertising that open in a new browser window or tab behind the current window, allowing users to discover the ad when they close or minimize their current browsing session.",
      description1: "The adsonick pop-under script delivers a 100% responsiveness."
    },
    {
      id: 2,
      title: "SocialBar Ads",
      imgsrc: socialbar,
      imgWidth: 400,
      imgHeight: 400,
      icon: <FaArrowRight />,
      description: "Social Bar offers publishers the ability to embed a customizable social sharing bar on their website, allowing them to promote their company's content, increase engagement, and drive social media traffic.",
      description1: "Publishers achieve higher CPMs while advertisers reach up to 30x higher CTRs in comparison with web push ads. Social Bar is fully customizable. Animated icons, videos, custom banners, and countdowns attract many clicks and prompt conversions."
    },
    {
      id: 3,
      title: "Push-Notification",
      imgsrc: push,
      imgWidth: 400,
      imgHeight: 400,
      icon: <FaArrowRight />,
      description: "Push ads are a form of digital advertising that delivers targeted messages directly to users' devices, appearing as notifications on their desktop or mobile screens, regardless of whether they are actively using an app or browsing a website. The most advanced replacement to web push notifications.",
      description1: "Push Ads by Adsonick don’t require opt-ins; they run on all OS and browsers. You can either use the built-in, animated templates, or design a customized ad."
    },
    {
      id: 4,
      title: "Native Ads",
      imgsrc: native,
      imgWidth: 400,
      imgHeight: 400,
      icon: <FaArrowRight />,
      description: "Native ads seamlessly blend into websites or apps, matching the visual and contextual style, providing a non-disruptive user experience while promoting products or content.",
      description1: "With Adsonick, advertisers run AdBlock-resistant and cost-effective CPC, CPM, and CPA native ads campaigns. With over 1 BN impressions weekly, native banners blend in web and mobile content and drive up to 8x higher CTRs compared to classic display ads."
    },
    {
      id: 5,
      title: "Video Ads",
      imgsrc: videoads,
      imgWidth: 400,
      imgHeight: 400,
      icon: <FaArrowRight />,
      description: "Video ads use captivating videos to deliver marketing messages, engaging users through audiovisual storytelling.",
      description1: "A short video that appears before the main content. We either use your VAST/VPAID tags, or host your videos. Video ads are great for creating visual impact: a brand video, a movie/game trailer, an immersive gambling video teasers, etc."
    },
    {
      id: 6,
      title: "Banner Ads",
      imgsrc: bannerad,
      imgWidth: 400,
      imgHeight: 400,
      icon: <FaArrowRight />,
      description: "Banners are graphical elements typically displayed on websites or apps, serving as a visual advertisement to convey information, promotions, or brand messages in a concise and eye-catching manner.",
      description1: "Online banner campaigns deliver unprecedented performance. Advertisers access reliable traffic sources from 18К publishers and reach conscious customers that are ready to buy. Publishers enjoy near 100% fill rates and higher payouts."
    },
  ];

  const Campaign=[
    {
      id:1,
      icon: <FaArrowRight />,
      name:"Targeting Audience"
    },
    {
      id:2,
      icon: <FaArrowRight />,
      name:"Geo-Graphical Reach"
    },
    {
      id:3,
      icon: <FaArrowRight />,
      name:"Best in vertical offers"
    },
    {
      id:4,
      icon: <FaArrowRight />,
      name:"Timely Payment Schedule"
    },
    {
      id:5,
      icon: <FaArrowRight />,
      name:"World Wide traffic inventories"
    },
    {
      id:6,
      icon: <FaArrowRight />,
      name:"Quality Assured Ad Campaigns"
    },
  ]

  return (
    <>
      <div className='dark:bg-black'>
        <div
          style={{
            backgroundImage: `url('${publisher}')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '40vh',
            opacity: 0.7,
          }}
          className='lg:text-[50px] sm:text-[40px] text-[30px] text-center font-bold dark:text-white py-52'>Publisher
          </div>
        {/* <div className='absolute lg:top-[150px] md:top-[300px] sm:top-[300px] top-[200px] lg:left-[500px] md:left-[250px] sm:left-[150px] left-[70px]'>
          <h1 className='lg:text-[50px] sm:text-[40px] text-[30px] text-center font-bold dark:text-white'>Publishers</h1>
        </div> */}
        <div className='lg:px-10 sm:px-5 px-2 lg:py-12 py-8'>
          <h1 className='lg:text-[30px] md:text-[20px] text-[18px] text-center font-semibold underline-border lg:pt-6 pt-4 lg:pb-3 pb-2 dark:text-purple-500'>Online Ads For Advertiser</h1>
          <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700  text-center dark:text-white'>Indulge in top-tier publishing services including editing, proofreading, typesetting, and cover design, all tailored to bring your vision to life. </p>
          <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 text-center dark:text-white'>Experience outstanding publishing services, including editing, proofreading, typesetting, cover design, and comprehensive marketing support, all delivered by our devoted team committed to realizing your vision.</p>
        </div>
        <div className='grid grid-cols-6 my-2 md:gap-3 gap-1 md:mx-6 sm:mx-4 mx-2'>
          {ads.map((ad, index) => (
            <div
              className={`bg-purple-200 dark:bg-white dark:text-purple-900 sm:px-2 px-1 md:py-4 py-2 rounded-sm hover:shadow-md hover:shadow-gray-400 cursor-pointer ${selectedAdIndex === index ? 'ring-2 ring-black' : ''}`}
              key={index}
              onClick={() => setSelectedAdIndex(index)}
            >
              <div className='lg:text-[40px] md:text-[30px] text-[20px] flex justify-center'>{ad.icon}</div>
              <h1 className='text-center lg:text-[18px] md:text-[16px] sm:text-[14px] text-[10px]  font-bold py-2'>{ad.name}</h1>
            </div>
          ))}
        </div>
        <div className='bg-purple-200 md:px-4 px-2 md:py-4 py-2 md:mx-6 sm:mx-4 mx-2 my-2 dark:bg-white dark:text-black'>
          <div className='grid sm:grid-cols-2 grid-cols-1'>
            <div>
              <img
                src={adsDetails[selectedAdIndex].imgsrc}
                width={adsDetails[selectedAdIndex].imgWidth}
                height={adsDetails[selectedAdIndex].imgHeight}
                className='rounded-md'
                alt={adsDetails[selectedAdIndex].title}
              />
            </div>
            <div>
              <h1 className='lg:text-[22px] md:text-[18px] sm:text-[16px] text-[14px] font-bold pb-2 sm:px-4 px-2 dark:text-purple-900'>{adsDetails[selectedAdIndex].title}</h1>
              <div className='flex py-2'><p className='lg:text-[16px] md:text-[15px] text-[14px] sm:px-4 px-2'>{adsDetails[selectedAdIndex].description}</p></div>
              <div className='flex py-2'><p className='lg:text-[16px] md:text-[15px] text-[14px] sm:px-4 px-2'>{adsDetails[selectedAdIndex].description1}</p></div>
            </div>
          </div>
        </div>
        <div className='lg:px-10 px-5 md:py-4 py-2 grid md:grid-cols-2 grid-cols-1'>
           <div>
            <h1 className='lg:text-[30px] md:text-[20px] text-[18px] text-center font-semibold sm:py-4 py-2 dark:text-purple-500'>Join As Publishers​</h1>
            <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 dark:text-white py-2'>Navigating users to an advertising page showcasing a range of products and services presents its own set of hurdles, but we're here to transform those challenges into opportunities. Our platform enables you to introduce end-users to advertiser products and services while also enhancing ad promotion, sustaining traffic, and ensuring you receive the commissions you deserve.</p>
           </div>
           <div className='mx-auto px-2 py-2 rounded-md shadow-lg md:w-[80%] shadow-gray-500 bg-purple-200 dark:bg-white dark:text-black my-6 '>
              <h1 className='py-4 px-2 text-center font-bold lg:text-[18px] text-[16px] dark:text-purple-900'>Our Modes of Campaign</h1>
              <div>
                {
                  Campaign.map((items,index)=>(
                <div className='flex px-2 py-2' key={index} >{items.icon}<p className='lg:text-[16px] md:text-[15px] text-[14px] hover:underline hover:underline-offset-8 px-3'>{items.name}</p></div>
                ))}
              </div>
              <div className='flex md:my-4 my-2 justify-center'><NavLink to={"/contactus"}><button className='bg-purple-900 md:px-4 px-2 py-2 lg:text-[16px] md:text-[15px] text-[14px] rounded-md text-white font-semibold'>Contact Us</button></NavLink></div>
            </div>
        </div>
      </div>
    </>
  );
}
