import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Advertiser from "./pages/Advertiser";
import Publishers from "./pages/Publishers";
import ContactUs from "./pages/ContactUs";
import Header from "./Components/Header"
import Footer from "./Components/Footer"
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsCondition from "./pages/TermsCondition";
import RefundCancillation from "./pages/RefundCancillation";


function App() {
  return (
    <div>
      <BrowserRouter>
       <Header/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/advertiser" element={<Advertiser/>}/>
        <Route path="/publishers" element={<Publishers/>}/>
        <Route path="/contactus" element={<ContactUs/>}/>
        <Route path="/privacy" element={<PrivacyPolicy/>}/>
        <Route path="/terms" element={<TermsCondition/>}/>
        <Route path="/refund" element={<RefundCancillation/>}/>
      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
