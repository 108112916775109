import React from 'react'
import background from "../assets/images/background.jpg"
import mobilead from "../assets/images/mobilead.png"
import email from "../assets/images/email (1).png"
import display from "../assets/images/yahoo.png"
import crucial from "../assets/images/crucial.png"
import brandawrness from "../assets/images/brandawarness.png"
import ranking from "../assets/images/ranking.png"
import webmarketing from "../assets/images/webmarketing1.png"



export default function Services() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    }
    const card=[
      {
        id:1,
        imgsrc: mobilead ,
        title:"Web & Mobile Advertising",
        description:"Our web and mobile advertising campaigns are designed to reach millions of smartphone users worldwide, resulting in over 10 million mobile app installs."
      },
      {
        id:2,
        imgsrc: email ,
        title:"SMS & Email Marketing",
        description:"Our email marketing and SMS strategies provide personalized solutions to connect with the right audience, enabling your brand to engage effectively on these platforms."
      },
      {
        id:3,
        imgsrc: display,
        title:"Display Advertising",
        description:"Display advertising visually communicates commercial messages through text, logos, animations, videos, and photographs."
      },
    ]
  

  const serviceCard=[
    {
      id:1,
      icon:<i class="fa-solid fa-video"></i>,
      title:"Video Engagement",
      description:"Video Engagement Our Video Engagement services produce captivating videos that connect with your audience, fostering meaningful interactions.",
    },
    {
      id:2,
      icon:<i class="fa-solid fa-tent"></i>,
      title:"Campaign Management",
      description:"Our Campaign Management services deliver results by crafting customized campaigns, optimization, and providing comprehensive insights.",
    },
    
    {
      id:3,
      icon:<i class="fa-brands fa-youtube"></i>,
      title:"YouTube Videos Views",
      description:"Elevate your YouTube videos through our targeted promotion and optimization services, ensuring maximum reach and engagement.",
    },
    {
      id:4,
      icon:<i class="fa-solid fa-globe"></i>,
      title:"Website Marketing",
      description:"Maximize your online presence with our Website Marketing services, driving targeted traffic and conversions tailored to your business.",
    },
    {
      id:5,
      icon:<i class="fa-solid fa-mobile-screen-button"></i>,
      title:"Mobile Marketing",
      description:"Leverage our Mobile Marketing services to expand your reach and enhance engagement through personalized campaigns designed for mobile devices, fostering growth and success for your business.",
    },
    {
      id:6,
      icon:<i class="fa-solid fa-photo-film"></i>,
      title:"Social Media Marketing",
      description:"Achieve optimal reach and engagement with our Social Media Marketing services, strategically tailored for success across popular platforms.",
    },
  ]
  return (
    <>
    <div>
    <div  style = {{
               backgroundImage:
               `url('${background}')`,
               backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
               width: '100%',
               height: '40vh',
               opacity: 0.7,
            }} className='lg:text-[50px] sm:text-[40px] text-[30px] text-center font-bold py-52'>Service
      </div>
      {/* <div className='absolute lg:top-[150px] md:top-[250px] sm:top-[268px] top-[250px] lg:left-[530px] md:left-[300px] sm:left-[200px] left-[100px] '>
              <h1 className='lg:text-[50px] sm:text-[40px] text-[30px] text-center font-bold'>Services</h1>
      </div> */}
      <div className=' dark:bg-black dark:text-white md:py-12 px-2'>
        <div className='container'>
          <h1 className='lg:text-[30px] md:text-[20px] text-[18px] font-semibold text-center dark:text-purple-500 py-2'>Explore Our Services</h1>
          <p className='lg:text-[16px] md:text-[15px] text-[14px] text-center text-gray-700 px-2 dark:text-white'>Adsonick is a leading company renowned in the industry for its Web and Mobile Web (M-Web) based brand advertising solutions. They excel in delivering impactful advertising campaigns that effectively utilize both web and mobile platforms to enhance brand visibility and engagement.</p>
        </div>
        <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:py-6 py-4 md:px-4 px-2 md:gap-4 gap-3 md:my-6 my-4'>
          {
            card.map((data,index)=>(
         <div className='shadow-gray-500 shadow-md border-gray-500 md:px-3 sm:px-2 px-1 md:py-6 py-4 rounded-md bg-purple-200 dark:bg-white' key={index}>
          <div className='pt-2'><img src={data.imgsrc} className='animate-bounce'/></div>
          <div><h1 className='lg:text-[18px] md:text-[16px] text-[15px]  font-bold md:pt-5 pt-2 dark:text-purple-900'>{data.title}</h1></div>
          <div><p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 py-2'>{data.description}</p></div>
         </div>
          ))}
        </div>
      </div>
      <div className='grid md:grid-cols-2 grid-cols-1 sm:py-4 md:px-4 px-2  bg-white dark:bg-black dark:text-white'>
        <div className=''>
          <img src={crucial} className='dark:text-white md:h-[300px] lg:h-[500px]'/>
        </div>
        <div className=''>
          <h1 className='lg:text-[30px] md:text-[20px] text-[18px] font-semibold dark:text-purple-500 px-2'>Leveraging social media is key to engaging a wide audience.</h1>
          <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 py-2 dark:text-white px-2'>By optimizing social media marketing, we help your brand build a dedicated community, generate excitement for your services, and attract visitors to your website.</p>
          <div className=''>
            <div className='flex lg:pt-6 pt-4'>
            <img src={brandawrness} className='dark:text-white text-[10px]'/>
            <h1 className='lg:text-[20px] md:text-[18px] sm:text-[16px] text-[14px] font-semibold md:px-4 px-2 dark:text-purple-500'>Improved Brand Awareness</h1>
            </div>
            <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 lg:px-6 md:px-4 px-2 dark:text-white'>Enhance your brand's presence on social media with our intelligent strategies designed to increase awareness and ensure your business is memorable to a wider audience.</p>
          </div>
          <div className=''>
            <div className='flex pt-8'>
            <img src={ranking} className="dark:text-white"/>
            <h1 className='lg:text-[20px] md:text-[18px] sm:text-[16px] text-[14px] font-semibold md:px-4 px-2 dark:text-purple-500'>Increased Traffic and SEO Ranking</h1>
            </div>
            <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 lg:px-6 md:px-4 px-2 dark:text-white'>Amplify your online presence by boosting website traffic and improving SEO rankings with our effective strategies.</p>
          </div>
        </div>
      </div>
      <div className='grid md:grid-cols-2 sm:py-4 md:px-4 px-2 my-4 bg-white dark:bg-black dark:text-white'>
      <div className=''>
      <h1 className='lg:text-[30px] sm:text-[20px] text-[18px] font-semibold dark:text-purple-500'>Cutting-Edge Digital Marketing Tactics for Emerging Businesses</h1>  
      <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700  dark:text-white py-4'>We understand the importance of establishing a strong online presence in today’s digital landscape, and we’re dedicated to helping businesses thrive in the virtual space.</p>
      <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 dark:text-white py-1'><i className="fa-solid fa-arrow-right px-2"></i>Enhance your online presence with our comprehensive web and mobile marketing strategies.</p>
      <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 dark:text-white py-1'><i className="fa-solid fa-arrow-right px-2"></i>Reach a wider audience through smartphones and tablets.</p>
      <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700 dark:text-white py-1'><i className="fa-solid fa-arrow-right px-2"></i>Collaborate with us to discover new opportunities and reach your online marketing objectives.</p>
      </div>  
      <div>
      <img src={webmarketing}/>
      </div>  
      </div> 
      <div className='md:px-4 px-2 py-4 bg-white dark:text-white dark:bg-black my-4'>
      <h1 className='lg:text-[30px] md:text-[20px] text-[18px] font-semibold text-center dark:text-purple-500'>What We Offers</h1>
      <p className='lg:text-[16px] md:text-[15px] text-[14px] text-gray-700  py-3 text-center lg:px-28 md:px-10 sm:px-8 px-2 dark:text-white'>We utilize diverse demand sources, including Programmatic Selling, Direct Campaigns, Ad Networks, and Affiliates. Additionally, we are advancing our ad placement technology to integrate with these sources using Data Analytics and DMP.</p>
      <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:gap-5 md:gap-4 gap-2 sm:py-4 py-2 md:px-4 sm:px-4 my-4'>
        {serviceCard.map((data,index)=>(
        <div className='rounded-md px-2 py-2 bg-purple-200 dark:bg-white my-4 hover:shadow-md hover:shadow-gray-500' key={index}>
          <div className='relative '>
          <span className='md:border-[3px] border-[2px] border-purple-900 lg:px-4 sm:px-2 px-1 lg:py-2 py-1 my-2 absolute rounded-[100%] bg-purple-200 top-[-55px] lg:left-[10px] left-1 text-[30px] text-purple-900'>{data.icon}</span>
            <h1 className='lg:text-[20px] md:text-[16px] text-[15px] font-bold text-center pt-3 dark:text-purple-900'>{data.title}</h1>
            <p className='lg:text-[16px] md:text-[15px] text-[14px] text-start font-medium py-2 text-gray-700 px-2'>{data.description}</p>
          </div>
        </div>
        ))}

      </div>
      </div>     
    </div>
      
    </>
  )
}
