import React from 'react'
import background from "../assets/images/background.jpg"
import { FaEnvelopeOpenText } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";

export default function ContactUs() {
  return (
    <>
    <div className=''>
      <div className = "image lg:text-[50px] sm:text-[40px] text-[30px] text-center font-bold py-52"
            style = {{
               backgroundImage:
               `url('${background}')`,
               backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
               width: '100%',
               height: '40vh',
               opacity: 0.7,
            }}>
              Contact Us
        </div>
        {/* <div className='absolute lg:top-[150px] md:top-[300px] sm:top-[300px] top-[250px] lg:left-[500px] md:left-[250px] sm:left-[100px] left-[100px]'>
              <h1 className='lg:text-[50px] sm:text-[40px] text-[30px] text-center font-bold'>Contact Us</h1>
          </div>  */}
      <div className='grid sm:grid-cols-2 grid-cols-1 md:py-16 py-10 px-2 dark:bg-black dark:text-white'>
        <div className=''>
          <p className='text-black font-semibold lg:text-[30px] md:text-[20px] text-[18px] md:pl-[20px] pl-[8px] dark:text-white'>Where You Can Find Us</p>
          <p className='text-gray-700 lg:text-[18px] sm:text-[16px] text-[14px]  md:py-2 py-1 lg:px-6 md:px-4 px-2 text-start dark:text-white'>We have offices from coast-to-coast and a global customer reach. Here is where you can find us:</p>
           <div className='md:pl-[24px] pl-[10px]'>
          <div className='flex sm:pt-4 pt-2 pb-1'><FaEnvelopeOpenText className='sm:text-[25px] text-[20px] my-2'/><h1 className='lg:text-[25px] sm:text-[20px] text-[18px] font-semibold underline md:px-6 px-4'>Email</h1></div>
          <div className='lg:text-[20px] md:text-[18px] sm:text-[16px] text-[14px] text-gray-700 md:pl-12 pl-6px dark:text-white'>Kishan.singla@adsonick.com</div>
          <div className='flex sm:pt-6 pt-4'><BsFillTelephoneFill className='sm:text-[25px] text-[20px] my-2'/><h1 className='lg:text-[25px] sm:text-[20px] text-[18px] font-semibold underline md:px-6 px-4' >Phone</h1></div>
          <div className='text-gray-700 md:pl-12  lg:text-[18px] sm:text-[16px] text-[14px] sm:py-2 py-1 dark:text-white'>+91-7206991113</div>
          <div className='text-gray-700 md:pl-12  lg:text-[18px] sm:text-[16px] text-[14px] dark:text-white'>+91-7206991113</div>
        </div>
        </div>
        <div className='contact-form rounded-md shadow-lg shadow-gray-600 dark:bg-white dark:text-black sm:my-0 my-8'>
            <form action='#' method='POST'>
              <h1 className='lg:text-[25px] text-[20px] font-bold md:px-4 px-2 pt-2'>Let’s Talk!</h1>
              <p className='lg:text-[18px] mdLtext-[16px] md:px-4 px-2 pb-3'>Fill out the form below to contact our team.</p>
              <div className='flex'>
              <input type='text' name='firstname' placeholder='Firstname' autoComplete='off' required className='boder-[1px] bg-gray-200 rounded-md  w-[50%] mx-2 my-2 py-2 placeholder-gray-700 px-2'/>
              <input type='text' name='lastname' placeholder='Lastname' autoComplete='off' required className='boder-[1px] bg-gray-200 rounded-md w-[50%] mx-2 my-2 py-2 placeholder-gray-700 px-2'/>
              </div>
              <input type='email' name='email' placeholder='Email Address' autoComplete='off' required className='boder-[1px] border-gray-500 bg-gray-200 rounded-md w-[90%] mx-2 my-2 py-2 placeholder-gray-700 px-2' />
              <input type='text' name='subject' placeholder='Subject' autoComplete='off' required className='boder-[1px] border-gray-500 bg-gray-200 rounded-md w-[90%] mx-2 my-2 py-2 placeholder-gray-700 px-2' />
            <textarea name='message' cols='30' rows="6" autoComplete='off' placeholder='Message' required className='boder-[1px] border-gray-500 bg-gray-200 rounded-md w-[90%] mx-2 my-2 py-2 placeholder-gray-700 px-2'/>
            <div className='flex py-4'><button className='bg-purple-500 px-2 py-2 text-white rounded-md mx-auto font-bold'>Submit Form</button></div>
            </form>
        </div>
      </div>
      <div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47475.35709596769!2d77.0373649423401!3d28.378994461005835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d237ef08a19cd%3A0xecb388da6e94559!2s3rd%20Floor%2C%20Landmark%20Cyber%20Park!5e0!3m2!1sen!2sin!4v1718345116161!5m2!1sen!2sin" width="100%" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
    </>
  )
}
